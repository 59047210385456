<template>
  <div>
    <header>
      <v-row no-gutters justify="center" style="height: 100%;">
        <v-col :align="colAlign" align-self="center">
          <div class="header__logo">
            <img src="../assets/logo_2.png" alt=""/>
          </div>
        </v-col>
      </v-row>
    </header>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  methods: {
    /**
     * モバイル判定
     */
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true;
        default:
          return false;
      }
    },
  },
  computed: {
    colAlign() {
      return (this.isMobile()) ? 'center' : 'start';
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  height: 90px;
  padding: 0 40px;
  .header__logo {
    width: 200px;
    img {
      height: auto;
      max-width: 100%;
      vertical-align: middle;
    }
  }
}
.theme--light.v-divider {
  border-color: #0095D9;
}
@media screen and (max-width: 599px) {
  header {
    padding: 0 0;
  }
}
</style>