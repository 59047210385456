import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import awsconfig from './aws-exports'
import Amplify, * as AmplifyModules from 'aws-amplify'
import router from './router/index'
import store from './store'
import VueGtm from '@gtm-support/vue2-gtm'
import axios from 'axios'

Amplify.configure(awsconfig)
Vue.config.productionTip = false
Vue.use(AmplifyModules)

// API用のbase url設定
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

// localhostではgoogle tag managerを利用しない
const localhost = (document.location.hostname === 'localhost' ||
                   document.location.hostname === '127.0.0.1')

if (!localhost) {
  const debug = (process.env.VUE_APP_ENV !== 'production')
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    // queryParams: {
    //   // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
    //   gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    //   gtm_preview: 'env-4',
    //   gtm_cookies_win: 'x',
    // },
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: process.env.VUE_APP_GTM_NONCE, // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: debug, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  });
}

// eslint-disable-line no-console
console.log(
  'main.js', process.env.VUE_APP_LOG_LEVEL, process.env.VUE_APP_ENV,
  process.env.VUE_APP_API_BASE_URL,
)

// ログレベルを環境で出し分ける
// https://docs.amplify.aws/lib/utilities/logger/q/platform/js/#installation
Amplify.Logger.LOG_LEVEL = process.env.VUE_APP_LOG_LEVEL || 'VERBOSE'

// Amplifyのゲストユーザーログイン
// TODO もし管理者機能を追加する場合は、router/indexに移動予定
Amplify.configure({
  'aws_appsync_authenticationType': 'AWS_IAM',
})

// Arrayにfirstを追加
Array.prototype.first = function(predicate) {
  for (let i = 0; i < this.length; i++) {
    if (!predicate) {
      return this[i];
    }
    if (predicate(this[i])) {
      return this[i];
    }
  }
  return null;
};

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
  created: () => (document.documentElement.setAttribute('lang', 'ja')),
}).$mount('#app')
