/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getKeycode = /* GraphQL */ `
  query GetKeycode($keycode: String!) {
    getKeycode(keycode: $keycode) {
      keycode
      version
      status
      email
      vaccinationTicketsNo
      acquiredDateAt
      createdAt
      updatedAt
    }
  }
`;
export const listKeycodes = /* GraphQL */ `
  query ListKeycodes(
    $keycode: String
    $filter: ModelKeycodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKeycodes(
      keycode: $keycode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        keycode
        version
        status
        email
        vaccinationTicketsNo
        acquiredDateAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byStatusAndVersion = /* GraphQL */ `
  query ByStatusAndVersion(
    $status: UsedStatus!
    $version: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKeycodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byStatusAndVersion(
      status: $status
      version: $version
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        keycode
        version
        status
        email
        vaccinationTicketsNo
        acquiredDateAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byStatusAndAcquiredDateAt = /* GraphQL */ `
  query ByStatusAndAcquiredDateAt(
    $status: UsedStatus!
    $acquiredDateAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelKeycodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byStatusAndAcquiredDateAt(
      status: $status
      acquiredDateAt: $acquiredDateAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        keycode
        version
        status
        email
        vaccinationTicketsNo
        acquiredDateAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byEmail = /* GraphQL */ `
  query ByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelKeycodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        keycode
        version
        status
        email
        vaccinationTicketsNo
        acquiredDateAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
