/**
 * 利用ステータス
 * @type {{UNUSED: string, USED: string}}
 */
export const UsedStatus = {
  UNUSED: 'UNUSED',
  USED: 'USED',
};

/**
 * ソート
 * @type {{ASC: string, DESC: string}}
 */
export const ModelSortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
};
