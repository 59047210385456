/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createKeycode = /* GraphQL */ `
  mutation CreateKeycode(
    $input: CreateKeycodeInput!
    $condition: ModelKeycodeConditionInput
  ) {
    createKeycode(input: $input, condition: $condition) {
      keycode
      version
      status
      email
      vaccinationTicketsNo
      acquiredDateAt
      createdAt
      updatedAt
    }
  }
`;
export const updateKeycode = /* GraphQL */ `
  mutation UpdateKeycode(
    $input: UpdateKeycodeInput!
    $condition: ModelKeycodeConditionInput
  ) {
    updateKeycode(input: $input, condition: $condition) {
      keycode
      version
      status
      email
      vaccinationTicketsNo
      acquiredDateAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteKeycode = /* GraphQL */ `
  mutation DeleteKeycode(
    $input: DeleteKeycodeInput!
    $condition: ModelKeycodeConditionInput
  ) {
    deleteKeycode(input: $input, condition: $condition) {
      keycode
      version
      status
      email
      vaccinationTicketsNo
      acquiredDateAt
      createdAt
      updatedAt
    }
  }
`;
