// https://vuex.vuejs.org/ja/guide/actions.html

import mutationTypes from '@/store/mutationTypes';
import { API, graphqlOperation, Logger } from 'aws-amplify';
import { UsedStatus } from '@/graphql/types';
import QRCode from 'qrcode';
import { byEmail, byStatusAndVersion } from '@/graphql/queries';
import { updateKeycode } from '@/graphql/mutations';
import moment from 'moment';
import axios from 'axios';

const logger = new Logger('actions');

// 取得済みに更新するために、有効な回答キーをまとめて取得する数
const BULK_LIST_KEYCODES_NUM = 50;
const ERRORS_NO_KEYCODE = 'すでに3つの回答キーを取得済みです。（代理で回答いただける人数は最大3人までとなります）';
const ERRORS_FAILED_AND_TRY_AGAIN = '回答キーの取得に失敗しました。再度お試しください。';

const Actions = {
  /**
   * 回答キーの取得
   * @param context
   * @param payload
   */
  getKeycode: async (context, payload) => {
    logger.info('getKeycode', payload);

    // 有効な回答キーから1件取得する
    const result = await getAvailableKeycode(context, payload);
    if (result) {
      context.commit(mutationTypes.SET_KEYCODE_RESPONSE, {
        ...result,
      });
      const qrCodeSrc = await QRCode.toDataURL(result.keycode);
      context.commit(mutationTypes.SET_QRCODE, qrCodeSrc);
    } else {
      // 取得できなかった場合、空にしておく
      context.commit(mutationTypes.SET_QRCODE, '');
    }
  },

  /**
   * 取得した回答キー情報をクリア
   * @param context
   */
  clearKeycode: (context) => {
    logger.info('clearKeycode');
    context.commit(mutationTypes.CLEAR_KEYCODE_RESPONSE);
    context.commit(mutationTypes.SET_QRCODE, '');
  },

  /**
   * Emailが登録済みかAPI経由で確認する
   * @param context
   * @param payload
   * @returns {Promise<boolean>}
   */
  validateEmailExists: async (context, payload) => {
    logger.info('validateEmailExists', payload);
    try {
      const result = await axios.get('/api/profile/emailExists', {
        params: {
          email: payload.email,
        },
        headers: {
          accept: 'application/json',
        },
      });
      logger.info('validateEmailExists.result', result, result.data.exists);

      return result.data.exists;
    } catch (e) {
      logger.error('validateEmailExists', e);
    }
    return false;
  },
};

/**
 * 有効な回答キーを取得する
 * @param context
 * @param payload
 * @returns {Promise<boolean>}
 */
async function getAvailableKeycode(context, payload) {
  logger.info('getAvailableKeycode', payload);

  // Emailで取得済み回答キーを検索する
  const retrievedResults = await byEmailApi(context, { email: payload.email });

  // Emailで取得済み回答キーが存在し、
  // さらに接種券番号が一致する回答キーが存在する場合はその回答キーを返す
  const retrievedKeycode = retrievedResults.first(
    x => x.vaccinationTicketsNo === payload.vaccinationTicketsNo);
  if (retrievedKeycode) {
    return retrievedKeycode;
  }

  // 存在しない場合、次のバージョンの回答キーを返す(初期値0代入)
  const max = retrievedResults.map(x => x.version)
    .reduce((prev, current) => Math.max(prev, current), 0);
  const nextVersion = max + 1;
  const unusedResults = await byStatusAndVersionApi({
    status: UsedStatus.UNUSED, // 未使用
    version: nextVersion,
  });

  // そもそも取得可能な回答キーがない場合
  if (!unusedResults || unusedResults.length === 0) {
    context.commit(
      mutationTypes.SET_KEYCODE_RESPONSE, { errors: ERRORS_NO_KEYCODE });
    return false;
  }

  // 有効な回答キーが存在する場合に回答キーを取得済みに更新する
  // 更新できるまで試み、最初に更新できた回答キーを返す
  const result = await updateKeycodeToUsed(unusedResults, payload);
  if (!result) {
    // 一定数のレコードの更新失敗
    // リトライを促す
    context.commit(mutationTypes.SET_KEYCODE_RESPONSE, {
      errors: ERRORS_FAILED_AND_TRY_AGAIN,
    });

    logger.info('getAvailableKeycode.retry message');
    return false;
  }

  return result;
}

/**
 * Emailから取得済み回答キーを取得する
 * @param context
 * @param payload
 * @returns {Promise<Array>}
 */
async function byEmailApi(context, payload) {
  logger.info('byEmailApi', payload);
  try {
    const result = await API.graphql(graphqlOperation(byEmail, {
      email: payload.email,
    }));
    logger.info('byEmailApi.result', result);
    return result.data.byEmail.items;
  } catch (e) {
    logger.error('byEmailApi', e);
  }
  return [];
}

/**
 * ステータス、バージョンを指定して回答キー取得APIを呼び出す
 * @param payload
 * @returns {Promise<boolean>}
 */
async function byStatusAndVersionApi(payload) {
  logger.info('byStatusAndVersionApi', payload);
  try {
    const result = await API.graphql(graphqlOperation(byStatusAndVersion, {
      status: payload.status,
      version: { eq: payload.version },
      limit: BULK_LIST_KEYCODES_NUM,
    }));
    logger.info('byStatusAndVersionApi.result', result);
    return result.data.byStatusAndVersion.items;
  } catch (e) {
    logger.error('byStatusAndVersionApi', e);
  }
  return false;
}

/**
 * 回答キーを取得済みに更新する
 * @param keycodes
 * @param payload
 * @returns {Promise<boolean|*>}
 */
async function updateKeycodeToUsed(keycodes, payload) {
  logger.info('updateKeycodeToUsed', keycodes, payload);

  // 有効な回答キーが存在する場合に回答キーを取得済みに更新する
  // 更新できるまで試み、最初に更新できた回答キーを返す
  for (const i in keycodes) {
    const record = keycodes[i];
    const result = await updateKeycodeToUsedApi(record.keycode, payload);
    if (result) {
      return record;
    }
  }

  return false;
}

/**
 * 指定の回答キーを取得済みに更新する
 * @param keycode
 * @param payload
 * @returns {Promise<boolean>}
 */
async function updateKeycodeToUsedApi(keycode, payload) {
  logger.info('updateKeycodeToUsedApi', keycode, payload);

  try {
    // API呼び出し
    const result = await API.graphql(graphqlOperation(updateKeycode, {
      input: {
        keycode: keycode,
        status: UsedStatus.USED, // 利用ステータス（利用済み）
        acquiredDateAt: moment().toISOString(true), // 取得日時(JST)
        email: payload.email, // Email
        vaccinationTicketsNo: payload.vaccinationTicketsNo, // 接種券番号
      },
      condition: {
        status: { eq: UsedStatus.UNUSED },
      },
    }));

    logger.info('updateKeycodeToUsed.result', result);
    return true;

    // 更新した回答キーを返す
  } catch (e) {
    logger.error('updateKeycodeToUsedApi', e);
  }

  return false;
}

export default Actions;
