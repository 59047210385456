// https://vuex.vuejs.org/ja/guide/state.html

const States = {
  // キーコード取得時のレスポンス
  keycodeResponse: {
    keycode: '',
    errors: '',
  },

  // QRコードimageのsrc
  qrCodeImageSrc: '',
}
export default States;
