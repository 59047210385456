<template>
  <v-main>
    <header-component/>

    <v-container class="container__wrapper mt-4 pt-0">
      <v-row no-gutters justify="center" class="my-4 my-md-8">
        <div class="text-body-2 text-md-h6 finish__border red--text pa-6">令和5年度 新型コロナワクチン接種後の健康状況調査は2024年3月12日をもって終了いたしました。</div>
      </v-row>
      <!-- 概要 -->
<!--      <v-row no-gutters justify="center" class="my-4 my-md-8">-->
<!--        <div class="text-body-2 text-md-h6 title__border pa-6">ご家族などの複数の回答をご希望の方はご利用ください</div>-->
<!--      </v-row>-->

<!--      <v-row no-gutters justify="start" class="mb-4">-->
<!--        <span class="text-h6 text-md-h5 font-weight-bold">ご利用の流れ</span>-->
<!--      </v-row>-->
<!--      <v-row no-gutters justify="start" class="mb-4">-->
<!--        <ul class="px-4 px-md-8">-->
<!--          <li><span class="my-0 text-body-2 text-md-body-1">アンケート対象者の接種券番号と代理者のメールアドレスを入力すると、回答キーを取得できます。</span>-->
<!--          </li>-->
<!--          <li><span class="my-0 text-body-2 text-md-body-1">-->
<!--            アプリで回答キーを入力すると、アンケートを回答いただけます。（詳細な手順は<a href="https://medoor.com/webmed/survey/mhlw/"-->
<!--                                                    target="_blank">こちらから</a>）</span></li>-->
<!--          <li><span class="my-0 text-body-2 text-md-body-1">代理で回答いただける人数は最大3人までとなります。（3つの回答キーが取得可能）</span>-->
<!--          </li>-->
<!--          <li>-->
<!--            <span-->
<!--                class="my-0 text-body-2 text-md-body-1 red&#45;&#45;text">アプリDoor.に医師として登録されている方は本サービスはご利用できません。-->
<!--            </span>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </v-row>-->
<!--      <v-row no-gutters justify="start" class="mb-4">-->
<!--        <span class="text-h6 text-md-h5  font-weight-bold">回答キーの取得</span>-->
<!--      </v-row>-->

      <!-- 入力フォーム -->
<!--      <v-form ref="form" v-model="valid">-->
<!--        &lt;!&ndash; 接種券番号 &ndash;&gt;-->
<!--        <v-row no-gutters justify="space-between" class="px-4 px-md-8 mb-2">-->
<!--          <v-col cols="10" align="start">-->
<!--            <span class="text-body-2 text-md-body-1 font-weight-bold">アンケート対象者の接種券番号（10桁）をご入力ください。</span>-->
<!--          </v-col>-->
<!--          <v-col align="end" cols="2"><span class="form__required">必須</span></v-col>-->
<!--        </v-row>-->
<!--        <v-row no-gutters justify="start" class="px-4 px-md-8">-->
<!--          <v-text-field-->
<!--              ref="VaccinationTicketsNo"-->
<!--              v-model="vaccinationTicketsNoText"-->
<!--              :rules="vaccinationTicketsNoRules"-->
<!--              type="tel"-->
<!--              inputmode="numeric"-->
<!--              required-->
<!--              placeholder="例:0123456789"-->
<!--              outlined-->
<!--              maxlength="10"-->
<!--              counter-->
<!--          >-->
<!--          </v-text-field>-->
<!--        </v-row>-->
<!--        &lt;!&ndash; メールアドレス &ndash;&gt;-->
<!--        <v-row no-gutters justify="space-between" class="px-4 px-md-8 mb-2">-->
<!--          <v-col cols="10" align="start">-->
<!--            <span class="text-body-2 text-md-body-1 font-weight-bold">アプリDoor.に登録のメールアドレスをご入力ください。</span>-->
<!--          </v-col>-->
<!--          <v-col align="end" cols="2"><span class="form__required">必須</span></v-col>-->
<!--        </v-row>-->
<!--        <v-row no-gutters justify="start" class="px-4 px-md-8">-->
<!--          <v-text-field-->
<!--              ref="email"-->
<!--              v-model="email"-->
<!--              :rules="emailRules"-->
<!--              type="text"-->
<!--              inputmode="text"-->
<!--              required-->
<!--              placeholder="例:door@medoor.com"-->
<!--              outlined-->
<!--              @input="inputEmail"-->
<!--              :error-messages="emailErrors"-->
<!--          >-->
<!--          </v-text-field>-->
<!--        </v-row>-->

<!--        &lt;!&ndash; 回答キー &ndash;&gt;-->
<!--        <v-row no-gutters justify="center" class="my-4">-->
<!--          <v-btn-->
<!--              :disabled="!enableGetKeycode"-->
<!--              :loading="loading"-->
<!--              class="mt-4"-->
<!--              color="#0095D9"-->
<!--              :dark="enableGetKeycode"-->
<!--              x-large-->
<!--              @click="clickedGetKeycode"-->
<!--          >-->
<!--            <span style="font-weight: bold">回答キーを取得する</span>-->
<!--          </v-btn>-->
<!--        </v-row>-->
<!--        <v-row no-gutters justify="center" class="mt-8" v-if="errors !== ''">-->
<!--          <span class="red&#45;&#45;text text-caption">{{ errors }}</span>-->
<!--        </v-row>-->
<!--        <v-row no-gutters justify="center" class="mt-10 mb-8">-->
<!--          <div class="keycode__border">-->
<!--            <p class="keycode__title text-center font-weight-bold text-h6 mx-auto">回答キー</p>-->
<!--            <p class="text-center font-weight-bold mt-0 mb-2"> {{ this.displayKeycode }}</p>-->
<!--            <div class="text-center" v-if="qrCodeImageSrc!==''">-->
<!--              <img class="d-none d-sm-inline-block" v-bind:src="qrCodeImageSrc" alt=""-->
<!--                   style="width: 160px; height: 160px;"/>-->
<!--            </div>-->
<!--            <v-btn-->
<!--                ref="copyButton"-->
<!--                v-if="!isEmptyKeycode"-->
<!--                v-on:click="copyText"-->
<!--                outlined-->
<!--                class="mb-6 mt-4 pt-0 d-md-none d-sm-none"-->
<!--                color="accentColor"-->
<!--            >-->
<!--              {{ copied ? 'コピーしました' : '回答キーをコピー' }}-->
<!--            </v-btn>-->
<!--          </div>-->
<!--        </v-row>-->
<!--      </v-form>-->
    </v-container>

    <!-- 注意事項 -->
    <v-container class="container__wrapper mb-4">
      <v-card
          class="mx-auto px-4 px-md-8 py-4 mb-4"
          outlined
      >
        <ul>
<!--          <li class="mb-2"><span class="text-body-2">アンケート回答手順については<a href="https://medoor.com/webmed/survey/mhlw/"-->
<!--                                                                      target="_blank">こちらから</a></span></li>-->
          <li class="mb-2"><span class="text-body-2">本サービスは、厚生労働省よりMRT株式会社に運営を委託しています。</span></li>
          <li class="mb-2"><span class="text-body-2">お問い合わせについては<a href="https://medoor.com/webmed/inquiry/"
                                                                   target="_blank">こちらから</a></span></li>
        </ul>
      </v-card>
    </v-container>

    <footer-component/>
  </v-main>
</template>

<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { Logger } from 'aws-amplify';
import { mapActions, mapGetters } from 'vuex';

const logger = new Logger('index');
const ERROR_MESSAGE_REQUIRED_VALIDATION = '接種券番号をご入力ください';
const ERROR_MESSAGE_DIGIT_VALIDATION = '接種券番号は10桁でご入力ください';
const ERROR_MESSAGE_NUMBER_VALIDATION = '半角数字でご入力ください';
const ERROR_MESSAGE_REQUIRED_EMAIL_VALIDATION = 'メールアドレスをご入力ください';
const ERROR_MESSAGE_EMAIL_VALIDATION = '正しいメールアドレスをご入力ください';
const ERROR_NOT_EXISTS_EMAIL = 'アプリDoor.で利用しているメールアドレスをご入力ください。';
const DEFAULT_KEYCODE = '・・・・・・';
// 接種券番号の最大桁数
const MAX_VACCINATION_TICKETS_NO_NUM = 10;
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Index',
  components: {
    'header-component': Header,
    'footer-component': Footer,
  },
  created() {
    logger.info('created');
  },
  data() {
    return {
      // 接種券番号
      inspectionDate: '',
      // フォーム入力チェック（trueで相談キー取得ボタンが押せる様になる）
      valid: false,
      // Emailのチェック
      validEmail: false,
      // 通信中（相談キー取得ボタンの表示切替用）
      loading: false,
      // 回答キーのコピー状態
      copied: false,
      // 接種券番号
      vaccinationTicketsNo: '',
      vaccinationTicketsNoRules: [
        v => !!v || ERROR_MESSAGE_REQUIRED_VALIDATION, // 未入力チェック
        v => v.search(/[^0-9０-９]/) < 0 || ERROR_MESSAGE_NUMBER_VALIDATION, // 半角数字チェック
        v => (!!v && v.length === MAX_VACCINATION_TICKETS_NO_NUM) || ERROR_MESSAGE_DIGIT_VALIDATION, // 桁数チェック
      ],
      // メールアドレス
      email: '',
      emailRules: [
        v => !!v || ERROR_MESSAGE_REQUIRED_EMAIL_VALIDATION, // 未入力チェック
        v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || ERROR_MESSAGE_EMAIL_VALIDATION;
        }, // メールアドレス形式チェック
      ],
      // メールアドレスのエラー配列(API経由の非同期バリデーションも含むため、別管理としている)
      emailErrors: [],
    };
  },
  methods: {
    // 回答キー取得ボタンが押された
    async clickedGetKeycode() {
      logger.info('clickedGetKeycode');
      try {
        // ローディング中に設定
        this.loading = true;
        // 表示している回答キーを非表示にする
        this.clearKeycode();
        // 回答キー取得
        await this.getKeycode({
          email: this.email,
          // 接種券番号にはバリデーション済みを指定する
          vaccinationTicketsNo: this.vaccinationTicketsNo,
        });
      } catch (e) {
        logger.error(e);
      } finally {
        this.loading = false;
      }
    },
    // 相談キーをクリップボードにコピーする
    async copyText() {
      try {
        const copyText = this.keycode;
        navigator.clipboard.writeText(copyText);
        this.copied = true;
        // 一定時間で戻す
        setTimeout(() => {
          this.copied = false;
        }, 2000);
      } catch (e) {
        logger.error(e);
      }
    },
    /**
     * モバイル判定
     */
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return true;
        default:
          return false;
      }
    },

    /**
     * メールアドレスが入力された
     */
    async inputEmail(value) {
      logger.info('inputEmail', value);
      this.validEmail = false;
      this.clearKeycode();

      // inputのタイミングでemailのvalidationが実行されていないため、明示的に実行する
      this.$refs.email.validate();

      // email形式で入力された場合に、検証する
      if (this.$refs.email.valid) {
        this.validEmail = await this.validateEmailExists({ email: this.email });
        // エラーメッセージを設定する
        this.emailErrors = (this.validEmail) ? [] : ERROR_NOT_EXISTS_EMAIL;
      }
    },

    /**
     * 全角数値を半角数値に変換する
     */
    toHalfWidthNumber: function (v) {
      return v.replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
    },

    ...mapActions(['getKeycode', 'clearKeycode', 'validateEmailExists']),
  },
  computed: {
    /**
     * 回答キー未入力か
     */
    isEmptyKeycode() {
      return this.displayKeycode === DEFAULT_KEYCODE;
    },
    /**
     * 表示する回答キー
     * @returns {string|string}
     */
    displayKeycode() {
      return this.keycode || DEFAULT_KEYCODE;
    },
    /**
     * 回答キー取得可能か
     */
    enableGetKeycode() {
      return this.valid && this.validEmail;
    },

    /**
     * 接種券番号
     */
    vaccinationTicketsNoText: {
      get: function () {
        return this.vaccinationTicketsNo;
      },
      set: function (v) {
        // 入力を半角数値として扱う
        this.vaccinationTicketsNo = this.toHalfWidthNumber(v);
      },
    },

    ...mapGetters([
      'keycode',
      'errors',
      'qrCodeImageSrc',
    ]),
  },
};
</script>

<style lang="scss" scoped>
// タイトル下のカラー区切り線
.title__box {
  height: 45px;
  font-size: 16px;
  background-color: #0095D9;
}

// サイトタイトル
.title__border {
  margin: 0 auto;
  border: 1px solid #0095D9;
  border-radius: 26px;
  display: block;
  //  min-width: 400px !important;
  text-align: center;
}

// 終了お知らせ
.finish__border {
  margin: 0 auto;
  border: 2px ridge red;
  border-radius: 26px;
  display: block;
  //  min-width: 400px !important;
  text-align: center;
}

// コンテナ設定
.container__wrapper {
  max-width: 1000px
}

// アラート（色付きの四角）
.v-alert ::v-deep .v-alert__content {
  font-size: 20px !important;
}

// キーコードを囲う線
.keycode__border {
  margin: 0 auto;
  border: 1px solid #0095D9;
  border-radius: 26px;
  display: block;
  min-width: 400px;
  text-align: center;
}

// キーコードタイトル
.keycode__title {
  background: #fff;
  margin-top: -15px;
  width: 30%;
}

// form 必須表示
.form__required {
  background-color: #C9171E;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  padding: 4px 8px;
  margin-right: 8px;
}

.form__hint {
  font-size: 12px;
}

@media screen and (max-width: 959px) {
  // キーコードを囲う線
  .keycode__border {
    min-width: 350px;
  }
}
</style>